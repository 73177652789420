<template>
    <v-row justify="center" align="start" class="fill-height" no-gutters>
        <v-col class="py-16 my-16" cols="12" align="center" v-if="getSelectedRole === null">
            <span class="text-h2 font-weight-bold grey--text text--darken-3">
                {{ $t('default') }}
            </span>
        </v-col>
        <v-col cols="9" align="center">
            <v-row no-gutters v-if="getSelectedRole === null">
                <v-col cols="6" align="center">
                    <v-card @click="$store.commit('setSelectedRole', 1);" class="optionItem mr-3" color="primary"
                        elevation="10">
                        <v-row no-gutters class="fill-height">
                            <v-col align-self="center">
                                <v-img :src="getImage('attendees')" width="150px" height="150px" contain></v-img>
                                <v-card-title class="justify-center text-h4 white--text font-weight-bold pt-10"> {{
                                    $t('role1')
                                }} </v-card-title>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
                <v-col cols="6" align="center">
                    <v-card @click="$store.commit('setSelectedRole', 2);" class="optionItem ml-5" color="primary"
                        elevation="10">
                        <v-row no-gutters class="fill-height">
                            <v-col align-self="center">
                                <v-img :src="getImage('exhibitor')" width="150px" height="150px" contain></v-img>
                                <v-card-title class="justify-center text-h4 white--text font-weight-bold pt-10"> {{
                                    $t('role2')
                                }} </v-card-title>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>

            <!-- The main options after selecting Role -->
            <v-row no-gutters class="fill-height mt-10" v-if="getSelectedRole === 1">
                <v-col align="center" cols="12" v-for="page in $t('homepageAttendee')" :key="page.displayPosition"
                    align-self="center">
                    <v-card @click="goto(page)" class="menuItem d-flex flex-column align-center justify-center my-6"
                        elevation="7" color="primary" height="70%">
                        <v-card-text class="text-h5 white--text">
                            <v-row no-gutters>
                                <v-col cols="5" align="right">
                                    <v-img :src="getImage(page.pageIcon)" width="30%" contain class="mx-10"></v-img>
                                </v-col>
                                <v-col cols="7" align="start" align-self="center" class="text-h4 font-weight-bold">
                                    {{ page.pageName }}
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-row no-gutters class="fill-height mt-10" v-if="getSelectedRole === 2">
                <v-col align="center" cols="12" v-for="page in $t('homepageExhibitor')" :key="page.displayPosition"
                    align-self="center">
                    <v-card @click="goto(page)" class="menuItem d-flex flex-column align-center justify-center my-6"
                        elevation="7" color="primary" height="70%">
                        <v-card-text class="text-h5 white--text">
                            <v-row no-gutters>
                                <v-col cols="5" align="right">
                                    <v-img :src="getImage(page.pageIcon)" width="30%" contain class="mx-10"></v-img>
                                </v-col>
                                <v-col cols="7" align="start" align-self="center" class="text-h4 font-weight-bold">
                                    {{ page.pageName }}
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
        <v-col cols="12">
            <languageBar v-if="getSelectedRole === null" style="margin-top: auto; min-height: 20vh;"></languageBar>
            <languageBar v-else style="margin-top: auto; min-height: 10vh;"></languageBar>
        </v-col>
    </v-row>
</template>

<script>
import languageBar from '@/components/languageBar'
import { mapGetters } from 'vuex'

export default {
    name: 'menuMatrix',
    components: {
        languageBar
    },
    data() {
        return {
        }
    },
    computed: {
        ...mapGetters(['getCMSlink', 'getTouchCount', 'isUserLooking', 'getSelectedRole']),
    },
    methods: {
        goto(page) {
            if (page.navigateType === 'navigate') {
                this.$router.push(page.route)
            } else {
                this.$store.commit('setVideoCallConfirmViewer', {
                    show: true,
                    message: page.speech
                })
            }
            this.$store.commit('setPageHeader', page.header)
            this.$store.commit('setDefaultBubbleText', true)
            this.$store.dispatch('avatarSpeak', page.speech)
        },

        getImage(location) {
            console.log(location)
            return require('../assets/' + location + '.png');
        }
    }
}
</script>

<style>
.menuItem {
    border: solid #2c6b87 !important;
    border-radius: 15px !important;
    width: 815px;
    height: 140px
}

.optionItem {
    border-radius: 20px !important;
    width: 393px;
    height: 301px;
}

.alignLangBar {
    bottom: 0 !important;
    position: relative;
}
</style>

<i18n>
{
  "en": {
      "default": "Are You A...",
      "role1": "ATTENDEE",
      "role2": "EXHIBITOR"
    },
  "es":{
      "default": "¿Le puedo ayudar en algo?",
      "role1": "Asistente",
      "role2": "Expositor"
    }
}
</i18n>
