<template>
  <v-row no-gutters align="start" class="mb-n16">
    <v-col cols="2">
      <v-card :height="appDimensions.height - 290" flat>
        <v-card-title class="text-h5 font-weight-bold"> MENU </v-card-title>
        <v-list class="pa-0">
          <v-list-item v-for="(item, i) in foodCategory" :key="i" class="pa-0">
            <v-btn @click="(selectedCategory = item.category)" elevation="0" tile
              :color="item.category === selectedCategory ? 'primary' : 'white'" width="100%" height="45">
              {{ item.category }}
            </v-btn>
          </v-list-item>
        </v-list>
      </v-card>
    </v-col>
    <v-col cols="8" align="center" justify="center">
      <v-card width="90%" flat>
        <v-row no-gutters>
          <v-col v-for="(foodItem, i) in foodItems" :key="i" cols="4">
            <v-card width="233px" height="309px" class="pa-3 ma-2" flat>
              <v-row no-gutters>
                <v-col cols="12">
                  <v-img :src="getImage(foodItem.foodImage)" width="232" height="183" contain></v-img>
                </v-col>
                <v-col cols="12">
                  {{ foodItem.name }}
                </v-col>
                <v-col cols="12">
                  ${{ foodItem.price }}
                </v-col>
                <v-col cols="12">
                  <v-card-actions class="justify-center">
                    <v-combobox label="Qty" v-model="foodItem.quantity" class="pr-2" :items="[1, 2, 3, 4, 5, 6]" dense
                      outlined filled height="28" hide-details style="width: 71px;"></v-combobox>
                    <v-btn @click="test(foodItem)" color="primary" width="108px" height="38px" elevation="0">Add</v-btn>
                  </v-card-actions>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
    <v-col cols="2" align="right">
      <v-card width="90%" :height="appDimensions.height - 290" flat class="foodOrder" color="#F4F6F6">
        <v-card-title class="justify-center"> CART </v-card-title>
        <v-divider></v-divider>
        <v-list color="#F4F6F6" :height="appDimensions.height - 500">
          <v-list-item v-for="(item, i) in getFoodCart" :key="i" class="pa-0 mb-10">
            <v-row class="px-2">
              <v-col cols="1" align-self="center" align="center" justify="center" >
                <v-icon @click="removeItem(item)" size="24" color="primary" class="pt-8">mdi-close-circle</v-icon>
              </v-col>
              <v-col cols="7" align="left" align-self="center">
                <v-row no-gutters class="pl-5">
                  <v-col cols="12">
                    <span class="font-weight-bold">{{ item.itemName }}</span>
                  </v-col>
                  <v-col cols="12">
                    <v-combobox v-model="item.quantity" :items="[1, 2, 3, 4, 5, 6]" outlined dense hide-details
                      style="width: 58px; height: 10px !important;"></v-combobox>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="4" class="font-weight-bold pt-8" align-self="center" > ${{ item.price }} </v-col>
            </v-row>
          </v-list-item>
        </v-list>
        <v-card-title class="px-16"> Total: <v-spacer></v-spacer> ${{ cost }} </v-card-title>
        <v-card-actions class="justify-center mb-3">
          <v-btn width="75%" @click="placeOrder()" color="primary"> Place Order </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import QrcodeVue from "qrcode.vue";
export default {
  name: "food",
  data() {
    return {
      selectedCategory: "APPETIZERS",
      cost: 0,
      pricing: null,
      selectedQuantity: null,
      errorMessages: [],
      foodCategory: [
        {
          category: 'APPETIZERS', menu: [
            { name: 'Cheese Bites', foodImage: 'cheese.png', category: 'food', customize: ['Pickle', 'Ketchup', 'Mustard'], price: 6.99, quantity: null },
            { name: 'Nachos', foodImage: 'nachos.png', category: 'food', customize: ['Ketchup', 'Mustard'], price: 4.99, quantity: null },
            { name: 'Potato Wedges', foodImage: 'wedges.png', category: 'food', customize: ['Ketchup', 'Mustard'], price: 4.99, quantity: null }
          ]
        },
        {
          category: 'MAIN COURSE', menu: [
            { name: 'Cheese Burger', foodImage: 'cheeseBurger.png', category: 'food', customize: ['Pickle', 'Ketchup', 'Mustard'], price: 13.99, quantity: null },
            { name: 'Hot Dog', foodImage: 'hotDog.jpg', category: 'food', customize: ['Ketchup', 'Mustard'], price: 13.99, quantity: null },
            { name: 'Chicken Wrap', foodImage: 'wrap.png', category: 'food', customize: ['Ketchup', 'Mustard'], price: 13.99, quantity: null },
          ]
        },
        {
          category: 'BEERS', menu: [
            { name: 'Corona', foodImage: 'corona.png', category: 'drink', price: 6.99, quantity: null },
            { name: 'Budlight', foodImage: 'budlight.jpeg', category: 'drink', price: 4.99, quantity: null },
            { name: 'Alagash White', foodImage: 'alagashwhite.png', category: 'drink', price: 7.99, quantity: null },
            { name: 'Coors Light', foodImage: 'coorlight.jpg', category: 'drink', price: 4.99, quantity: null }
          ]
        }
      ],

    };
  },
  components: {
    QrcodeVue,
  },
  computed: {
    ...mapGetters(['getFoodCart', 'getCurrentLanguage', 'appDimensions']),
    foodItems() {
      console.log('Selected Category', this.selectedCategory, this.foodCategory.filter(menu => menu.category.toLowerCase() === this.selectedCategory.toLowerCase()))
      return this.foodCategory.filter(menu => menu.category.toLowerCase() === this.selectedCategory.toLowerCase())[0].menu
    },
    textColor() {
      return this.selectedCategory === '' ? "white" : "black";
    }

  },
  watch: {
    getFoodCart(newItem) {
      console.log('New Item: ', newItem)
      if (newItem.length > 0) {
        this.cost = this.cost + newItem[newItem.length - 1].price
      }
    }
  },
  methods: {
    calculateTotal(price) {
      return this.cost + price
    },
    filteredFoodCategory(type) {
      console.log('Food Category Type: ', type)
    },
    test(item) {
      console.log('Food Items: ', item)
      if (item.type === 'food') {
        let data = {
          itemName: item.name,
          price: Math.round((item.quantity * item.price) * 100) / 100,
          quantity: item.quantity,
          type: 'food'
        }
        this.$store.commit('setFoodCart', data)
        item.quantity = null
      } else {
        let data = {
          itemName: item.name,
          price: Math.round((item.quantity * item.price) * 100) / 100,
          quantity: item.quantity,
          type: 'drink'
        }
        this.$store.commit('setFoodCart', data)
        item.quantity = null
      }
    },
    getImage(image) {
      return require('../assets/food/' + image);
    },
    removeItem(item) {
      let index = this.getFoodCart.findIndex(cart => cart.itemName === item.itemName);
      if (index !== -1) {
        this.cost = this.cost - item.price
        this.getFoodCart.splice(index, 1);
      }
    },
    placeOrder() {
      if (this.cost !== 0) {
        if (this.getCurrentLanguage === 'en-US') {
          this.$store.dispatch('avatarSpeak', "Your order is placed, please go to the counter to retrieve it.")
        } else {
          this.$store.dispatch('avatarSpeak', "Su pedido está realizado, por favor diríjase al mostrador para recuperarlo.")
        }
        this.$store.commit('emptyFoodCart');
        this.cost = 0
      } else {
        if (this.getCurrentLanguage === 'en-US') {
          this.$store.dispatch('avatarSpeak', "Your food cart is empty. Please order food to place an order")
        } else {
          this.$store.dispatch('avatarSpeak', "Tu carrito de comida está vacío. Por favor pida comida para hacer un pedido")
        }
      }

    }
  },
  filters: {
    trimLength(val) {
      if (val.length < 90) {
        return val;
      }
      return `${val.substring(0, 60)}...`;
    },
  },
  mounted() { },
};
</script>

<style>
.menuItem1 {
  border: thin solid #4464FC !important;
  height: 200px;
  width: 250px;
  margin-top: 10px;
}

.foodOrder {
  width: 90%;
}

.foodCategory {
  border: thin solid #4464FC !important;
}
</style>