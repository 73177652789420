<template>
  <v-row no-gutters align="start" class="mb-n16">
    <v-col cols="3">
      <v-card flat class="mx-3" :height="appDimensions.height - 290" color="#F4F6F6">
        <v-card-title class="justify-center mb-5">{{ $t('header') }}</v-card-title>
        <v-row>
          <v-col cols="12" v-for="(map, i) in maps" :key="i" align="center">
            <v-btn width="95%" height="50" :color="map.path === url ? 'primary' : 'white'" 
            @click="url = map.path" depressed class="font-weight-bold">
              {{ map.name }}</v-btn>
          </v-col>
        </v-row>

      </v-card>
    </v-col>
    <v-col cols="9">
      <v-row no-gutters>
        <v-col>
          <v-card width="100%" height="70" flat color="primary" class="containerBorder2">
            <v-card-title class="text-h4 justify-center white--text">Orlando Convention Center</v-card-title>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col align="center">
          <v-img :src="getImage(url)" width="69%"> </v-img>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "maps",
  data() {
    return {
      active_tab: 1,
      url: "",
      floorNum: null,
      showList: false,
      count: 0
    };
  },
  computed: {
    ...mapGetters(["getMaps", "getCMSlink", "appDimensions"]),
    maps() {
      return [{ name: 'Management Office', path: 'management' },
      { name: 'Orlando Tourism', path: 'tourism' }, { name: 'Popshap', path: 'smartsource' }, { name: 'Restroom', path: 'restroom' }];
    },
  },
  methods: {
    getImage(location) {
      console.log('Requested Location', '../assets/maps/' + location + '.gif')
      //this.url = '../assets/maps/' + location + '.png';
      return require('../assets/maps/' + location + '.gif');
    }
  },
  filters: {
    trimLength(val) {
      if (val.length < 20) {
        return val;
      }
      return `${val.substring(0, 20)}...`;
    },
  },
  mounted() {
    this.url = 'management'
    //this.getImage('floor')
  },
};
</script>
<i18n>
{
  "en": {
      "header": "MAP DIRECTORY"
    },
  "es":{
      "header": "Directorio Mapas"
    }
}
</i18n>
<style scoped>
.containerBorder2 {
  border-radius: 0px !important;
  border: solid #2c6b87 !important;
}
</style>