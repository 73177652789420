<template>
  <v-dialog v-model="getImageViewer.show" width="65%" @click:outside="$store.commit('closeImageViewer')">
    <v-card width="100%">
      <v-row no-gutters>
        <v-col align="center">
          <v-card-text class="text-h4 pl-16 font-weight-bold">{{ getImageViewer.name }}</v-card-text>
        </v-col>
        <v-col cols="1" align="center">
          <v-card-text class="d-flex justify-end" style="overflow-y: hidden">
            <v-progress-circular @click="$store.commit('closeImageViewer')" :rotate="180" :size="65" :width="7"
              :value="closePercentage" color="error">
              <v-icon large color="error"> mdi-close </v-icon>
            </v-progress-circular>
          </v-card-text>
        </v-col>
        <v-col cols="12">
          <v-img :src="getImage(url)" v-if="getImageViewer.show"></v-img>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "imageViewer",
  data() {
    return {
      closePercentage: 0,
      timeout: 0,
      intervalObj: 0,
      url: ''
    };
  },
  computed: {
    ...mapGetters(["getImageViewer", "getIsSpeaking"]),
  },
  watch: {
    getIsSpeaking(newVal) {
      if (!newVal) {
        clearTimeout(this.timeout);
        this.intervalObj = setInterval(() => {
          this.closePercentage += 20;
        }, 4000);
        this.timeout = setTimeout(() => {
          clearInterval(this.intervalObj);
          this.closePercentage = 0;
          this.$store.commit("closeImageViewer");
        }, 20000);
      } else {
        this.closePercentage = 0;
        clearInterval(this.intervalObj);
        clearTimeout(this.timeout);
      }
    },
    getImageViewer(newVal) {
      if (newVal.show) {
        this.url = newVal.url
      }
    }
  },
  methods: {
    getImage(location) {
      //this.url = '../assets/maps/' + location + '.png';
      return require('../../assets/maps/' + location + '.gif');
    }
  },
};
</script>

<style scoped>

</style>
