<template>
    <v-row no-gutters class="mb-n16">
        <v-col align="center">
            <v-card flat width="95%" :height="appDimensions.height - 290">
                <v-row no-gutters class="fill-height" align="center" justify="center">
                    <v-col cols="6">
                        <v-card :height="appDimensions.height - 420" width="80%" color="primary"
                            class="d-flex align-center justify-center">
                            <v-row>
                                <v-col cols="12">
                                    <v-card-text class="white--text text-h3 font-weight-bold">CHOOSE A CATEGORY <br> TO
                                        EXPLORE</v-card-text>
                                </v-col>
                                <v-col cols="12">
                                    <v-card-actions>
                                        <v-img src="@/assets/tourism/navigator.png" width="84" height="84" contain></v-img>
                                    </v-card-actions>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                    <v-col cols="6">
                        <v-row no-gutters align="center" justify="center">
                            <v-col cols="4" v-for="(category, i) in categoryDisplay" :key="i">
                                <v-card height="311px" width="263px" class="ma-5" @click="showCategory(category.name)">
                                    <v-img :src="getImage(category)" height="250" width="300" class="mb-3"></v-img>
                                    <span class="font-weight-bold primary--text">{{ category.name }}</span>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-dialog v-model="getTourismOption.themepark" width="70%" persistent>
                    <v-card>
                        <v-card-title class="justify-center">
                            <v-row no-gutters>
                                <v-col cols="1"></v-col>
                                <v-col cols="10" align-self="center" align="center" class="font-weight-bold text-h2 pb-10">
                                    THEME PARKS
                                </v-col>
                                <v-col cols="1" align="right">
                                    <v-icon color="primary" x-large @click="$store.commit('setTourismOptions', 'CLOSE')"
                                        class="d-flex justify-right">mdi-close-circle</v-icon>
                                </v-col>
                            </v-row>
                        </v-card-title>
                        <v-row no-gutters class="fill-height" align="center" justify="center">
                            <v-col v-for="(place, i) in resorts" :key="i" cols="3">
                                <v-card class="ma-3 pb-3" width="318px" height="405px">
                                    <v-img :src="getImage(place)" height="250">
                                    </v-img>
                                    <v-card-title class="black--text text-subtitle-1 font-weight-bold">{{ place.name
                                    }}</v-card-title>
                                    <v-card-text class="black--text text-subtitle-2 font-weight-bold">
                                        <v-icon class="pr-3" color="primary">mdi-map-marker</v-icon>
                                        {{ place.address }}
                                    </v-card-text>
                                    <v-card-actions class="pa-0 ma-0 justify-center">
                                        <v-btn color="primary" width="90%">VIEW WEBSITE</v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="getTourismOption.eatDrink" width="70%" persistent>
                    <v-card>
                        <v-card-title class="justify-center">
                            <v-row no-gutters>
                                <v-col cols="1"></v-col>
                                <v-col cols="10" align-self="center" align="center" class="font-weight-bold text-h2 pb-10">
                                    EAT & DRINK
                                </v-col>
                                <v-col cols="1" align="right">
                                    <v-icon color="primary" x-large @click="$store.commit('setTourismOptions', 'CLOSE')"
                                        class="d-flex justify-right">mdi-close-circle</v-icon>
                                </v-col>
                            </v-row>
                        </v-card-title>
                        <v-row no-gutters class="fill-height" align="center" justify="center">
                            <v-col v-for="(place, i) in eat" :key="i" cols="3">
                                <v-card class="ma-3 pb-3" width="95%">
                                    <v-img :src="getImage(place)" height="250">
                                    </v-img>
                                    <v-card-title class="black--text text-subtitle-1 font-weight-bold">{{ place.name
                                    }}</v-card-title>
                                    <v-card-text class="black--text text-subtitle-2 font-weight-bold">
                                        <v-icon class="pr-3" color="primary">mdi-map-marker</v-icon>
                                        {{ place.address }}
                                    </v-card-text>
                                    <v-card-actions class="pa-0 ma-0 justify-center">
                                        <v-btn color="primary" width="90%">VIEW WEBSITE</v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="getTourismOption.attractions" width="70%" persistent>
                    <v-card>
                        <v-card-title class="justify-center">
                            <v-row no-gutters>
                                <v-col cols="1"></v-col>
                                <v-col cols="10" align-self="center" align="center" class="font-weight-bold text-h2 pb-10">
                                    ATTRACTIONS
                                </v-col>
                                <v-col cols="1" align="right">
                                    <v-icon color="primary" x-large @click="$store.commit('setTourismOptions', 'CLOSE')"
                                        class="d-flex justify-right">mdi-close-circle</v-icon>
                                </v-col>
                            </v-row>
                        </v-card-title>
                        <v-row no-gutters class="fill-height" align="center" justify="center">
                            <v-col v-for="(place, i) in attraction" :key="i" cols="3">
                                <v-card class="ma-3 pb-3" width="95%">
                                    <v-img :src="getImage(place)" height="250">
                                    </v-img>
                                    <v-card-title class="black--text text-subtitle-1 font-weight-bold">{{ place.name
                                    }}</v-card-title>
                                    <v-card-text class="black--text text-subtitle-2 font-weight-bold">
                                        <v-icon class="pr-3" color="primary">mdi-map-marker</v-icon>
                                        {{ place.address }}
                                    </v-card-text>
                                    <v-card-actions class="pa-0 ma-0 justify-center">
                                        <v-btn color="primary" width="90%">VIEW WEBSITE</v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="getTourismOption.history" width="70%" persistent>
                    <v-card>
                        <v-card-title class="justify-center">
                            <v-row no-gutters>
                                <v-col cols="1"></v-col>
                                <v-col cols="10" align-self="center" align="center" class="font-weight-bold text-h2 pb-10">
                                    HISTORY
                                </v-col>
                                <v-col cols="1" align="right">
                                    <v-icon color="primary" x-large @click="$store.commit('setTourismOptions', 'CLOSE')"
                                        class="d-flex justify-right">mdi-close-circle</v-icon>
                                </v-col>
                            </v-row>
                        </v-card-title>
                        <v-row no-gutters class="fill-height" align="center" justify="center">
                            <v-col v-for="(place, i) in history" :key="i" cols="3">
                                <v-card class="ma-3 pb-3" width="95%">
                                    <v-img :src="getImage(place)" height="250">
                                    </v-img>
                                    <v-card-title class="black--text text-subtitle-1 font-weight-bold">{{ place.name
                                    }}</v-card-title>
                                    <v-card-text class="black--text text-subtitle-2 font-weight-bold">
                                        <v-icon class="pr-3" color="primary">mdi-map-marker</v-icon>
                                        {{ place.address }}
                                    </v-card-text>
                                    <v-card-actions class="pa-0 ma-0 justify-center">
                                        <v-btn color="primary" width="90%">VIEW WEBSITE</v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="getTourismOption.shopping" width="70%" persistent>
                    <v-card>
                        <v-card-title class="justify-center">
                            <v-row no-gutters>
                                <v-col cols="1"></v-col>
                                <v-col cols="10" align-self="center" align="center" class="font-weight-bold text-h2 pb-10">
                                    SHOPPING
                                </v-col>
                                <v-col cols="1" align="right">
                                    <v-icon color="primary" x-large @click="$store.commit('setTourismOptions', 'CLOSE')"
                                        class="d-flex justify-right">mdi-close-circle</v-icon>
                                </v-col>
                            </v-row>
                        </v-card-title>
                        <v-row no-gutters class="fill-height" align="center" justify="center">
                            <v-col v-for="(place, i) in shop" :key="i" cols="3">
                                <v-card class="ma-3 pb-3" width="95%">
                                    <v-img :src="getImage(place)" height="250" v-if="!showQR">
                                    </v-img>
                                    <v-card-title class="black--text text-subtitle-1 font-weight-bold">{{ place.name
                                    }}</v-card-title>
                                    <v-card-text class="black--text text-subtitle-2 font-weight-bold">
                                        <v-icon class="pr-3" color="primary">mdi-map-marker</v-icon>
                                        {{ place.address }}
                                    </v-card-text>
                                    <v-card-actions class="pa-0 ma-0 justify-center">
                                        <v-btn color="primary" width="90%" @click="generateQr(place)">VIEW WEBSITE</v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-dialog>
            </v-card>
        </v-col>
    </v-row>
</template>
<script>
import QrcodeVue from 'qrcode.vue';
import { mapGetters } from 'vuex';
export default {
    name: 'tourism',
    components: {
        QrcodeVue
    },
    computed: {
        ...mapGetters(['appDimensions', 'getTourismOption'])
    },
    data() {
        return {
            tab: 0,
            categoryDisplay: [{ name: 'THEME PARKS', image: 'themepark.webp' }, { name: 'ATTRACTIONS', image: 'attraction.webp' },
            { name: 'EAT AND DRINK', image: 'eat.webp' }, { name: 'HISTORY', image: 'history.webp' }, { name: 'SHOPPING', image: 'shopping.webp' }],

            resorts: [{ name: 'WALT DISNEY WORLD RESORT', image: 'waltdisney.webp', address: '1180 Seven Seas Drive', link: 'https://disneyworld.disney.go.com/vacation-planning/?ef_id=Cj0KCQjw0tKiBhC6ARIsAAOXutlsIxxWwatEE1AiQQYDbL9hPRK1Dz8kjTkEk1VZbtOFj7tXDqGo-s8aAj0uEALw_wcB:G:s&s_kwcid=AL!5060!3!598402914601!e!!g!!walt%20disney%20world%20theme%20park&CMP=KNC-FY23_WDW_TRA_DXF_W365_RES_RCV_Vacation%7CG%7C5231213.RR.AM.01.01%7CMO75OQR%7CBR%7C598402914601&keyword_id=aud-365940100997:kwd-76540300%7Cdc%7Cwalt%20disney%20world%20theme%20park%7C598402914601%7Ce%7C5060:3%7C&gclid=Cj0KCQjw0tKiBhC6ARIsAAOXutlsIxxWwatEE1AiQQYDbL9hPRK1Dz8kjTkEk1VZbtOFj7tXDqGo-s8aAj0uEALw_wcB' },
            { name: 'UNIVERSAL ORLANDO RESORT', image: 'universalOrlando.webp', address: '6000 Universal Boulevard', link: 'https://www.universalorlando.com/web/en/us/theme-parks/universal-studios-florida' },
            { name: 'SEAWORLD ORLANDO', image: 'seaworld.webp', address: '7007 SeaWorld Drive', link: 'https://seaworld.com/orlando/' }],

            eat: [{ name: 'OLE RED', image: 'olered.jpg', address: '8417 International Drive', link: 'https://olered.com/orlando/' },
            { name: "ICEBAR", image: 'icebar.jpg', address: '8967 International Drive', link: 'https://icebarorlando.com/' },
            { name: 'ARTISAN`S TABLE', image: 'artisans.jpg', address: '55 West Church Street, Suite 128', link: 'https://artisanstableorlando.com/' }],

            shop: [{ name: 'THE MALL AT MILLENIA', image: 'millenia.webp', address: '4200 Conroy Road', link: 'https://www.mallatmillenia.com/' },
            { name: "THE FLORIDA MALL", image: 'floridamal.webp', address: '8001 South Orange Blossom Trail', link: 'https://www.simon.com/mall/the-florida-mall' },
            { name: 'LAKE BUENA VISTA FACTORY STORES', image: 'prada.webp', address: '15657 State Road 535', link: 'https://lbvfs.com/' }],

            attraction: [{ name: 'BOGGY CREEK AIRBOAT ADVENTURES', image: 'airboat.webp', address: '2001 East Southport Road, Kissimmee', link: 'https://bcairboats.com/' },
            { name: "CLEARWATER MARINE AQUARIUM", image: 'aquarium.webp', address: '249 Windward Passage, Clearwater', link: 'https://www.cmaquarium.org/visit/' },
            { name: 'CHOCOLATE KINGDOM', image: 'chocolateKingdom.webp', address: '9901 Hawaiian Court', link: 'https://www.chocolatekingdom.com/' }],

            history: [{ name: 'PULSE INTERIM MEMORIAL', image: 'pulse.jpg', address: '1227 East Concord Street', link: 'https://onepulsefoundation.org/' },
            { name: "ORLANDO MUSEUM OF ART", image: 'orlandoMuseum.webp', address: '2416 N Mills Ave', link: 'https://omart.org/' },
            { name: 'CHINESE TING', image: 'chineseting.webp', address: 'Lake Eola Park', link: 'https://richesmi.cah.ucf.edu/omeka/exhibits/show/cflmonuments/lakeeolating' }],

            videoElement: null,
            videoPlayed: false,
            show: {
                themepark: false,
                attractions: false,
                eatDrink: false,
                history: false,
                shopping: false
            },
            showQR: false,
            value: '',
            size: 225
        }
    },
    methods: {
        getImage(place) {
            console.log('Place:', place)
            return require('@/assets/tourism/' + place.image);
        },
        videoUpdate(value) {
            this.videoElement = document.getElementById('myVideo')
            if (value === 'video') {
                this.videoPlayed = true
                //this.videoElement.play()
            } else {
                if (this.videoPlayed) {
                    this.videoPlayed = false
                    this.videoElement.pause()
                    this.videoElement.currentTime = 0
                }

            }
        },
        generateQr(item) {
            console.log('Item: ', item)
            this.showQR = true
            this.value = item.link
        },
        showCategory(cat) {
            this.$store.commit("setTourismOptions", cat)
        }
    },
    mounted() {

    }
}
</script>
//Barrels & Billets