<template>
  <v-row no-gutters class="d-flex justify-center align-end">
    <v-col @click="changeLanguage('en-US')" :cols="colLength" align="center">
      <v-card width="211" flat>
        <v-row no-gutters>
          <v-col cols="3">
            <v-avatar style="z-index: 1" size="65">
              <v-img src="@/assets/en.png"></v-img>
            </v-avatar>
          </v-col>
          <v-col cols="9" align="start" align-self="center">
            <v-card-text class="text-h5 font-weight-bold">
              English
            </v-card-text>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
    <v-col @click="changeLanguage('es-US')" :cols="colLength" align="center">
      <v-card width="211" flat>
        <v-row no-gutters>
          <v-col cols="3">
            <v-avatar style="z-index: 1" size="65">
              <v-img src="@/assets/es.png"></v-img>
            </v-avatar>
          </v-col>
          <v-col cols="9" align="start" align-self="center">
            <v-card-text class="text-h5 font-weight-bold">
              Spanish
            </v-card-text>
          </v-col>
        </v-row>
      </v-card>
      <!-- <v-avatar style="z-index: 1" size="65">
        <v-img src="@/assets/es.png"></v-img>
      </v-avatar> -->
      <!-- <v-btn elevation="0" large class="pl-10 py-7 font-weight-bold"
        v-bind:color="getCurrentLanguage === 'es-US' ? 'grey lighten-2' : 'white'"> Spanish </v-btn> -->
    </v-col>
    <v-col :cols="colLength" v-if="(getSelectedRole !== null)" align="center">
      <v-card flat v-if="(getSelectedRole === 1)" @click="$store.commit('setSelectedRole', 2);" color="#F4F6F6"
        width="211">
        <v-card-text class="text-h5 font-weight-bold black--text">
          Exhibitor
        </v-card-text>
      </v-card>
      <v-card flat v-if="(getSelectedRole === 2)" @click="$store.commit('setSelectedRole', 1);" color="#F4F6F6"
        width="211">
        <v-card-text class="text-h5 font-weight-bold black--text">
          Attendee
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "languageBar",
  computed: {
    ...mapGetters(["getTouchCount", "getCurrentLanguage", "getSelectedRole"]),
  },
  data() {
    return {
      colLength: 4
    }
  },
  watch: {
    getCurrentLanguage(newVal) {
      if (newVal === 'en-US') {
        this.$i18n.locale = "en";
      }
    },
    getSelectedRole(newVal) {
      if (newVal != null) {
        this.colLength = 3
      }
    }
  },
  mounted() {
    if (this.getSelectedRole !== null) {
      this.colLength = 3
    } else {
      this.colLength = 4
    }
  },
  methods: {
    changeLanguage(language) {
      switch (language) {
        case "en-US":
          //this.$store.commit("setTouchCount", this.getTouchCount + 1);
          this.$i18n.locale = "en";
          this.$store.commit("setCurrentLanguage", "en-US");
          this.$store.dispatch("avatarSpeak", "I am now speaking in english");
          this.$store.commit("setTouchRequest", {
            module: "Language Bar",
            action: "Change Language",
            response: "Language changed to English",
            timeStamp: new Date(),
            requestType: "Touch",
          });
          break;
        case "es-US":
          //this.$store.commit("setTouchCount", this.getTouchCount + 1);
          this.$i18n.locale = "es";
          this.$store.commit("setCurrentLanguage", "es-US");
          this.$store.dispatch(
            "avatarSpeak",
            "El reconocimiento de voz está desactivado para el idioma español."
          );
          this.$store.commit("setTouchRequest", {
            module: "Language Bar",
            action: "Change Language",
            response: "Language changed to Spanish",
            timeStamp: new Date(),
            requestType: "Touch",
          });
          //this.$store.dispatch('avatarSpeak', 'Estoy aprendiendo español. Por favor regresa más tarde.');
          break;
        default:
          break;
      }
    },
  },
};
</script>
