<template>
    <v-dialog width="35%" v-model="getVideoCallConfirmViewer.show"
        @click:outside="$store.commit('closeVideoCallConfirmViewer')">
        <v-card>
            <v-card-title class="justify-center py-3">{{ getVideoCallConfirmViewer.message }}</v-card-title>
            <v-divider></v-divider>
            <v-card-actions class="justify-center mt-3">
                <v-btn @click="liveCall()" width="150" color="primary"> Continue </v-btn>
                <v-btn @click="$store.commit('closeVideoCallConfirmViewer')" width="150"> Cancel </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    name: 'confirmVideoCall',
    computed: {
        ...mapGetters(['getVideoCallConfirmViewer'])
    },
    methods: {
        liveCall() {
            this.$store.dispatch('initiateCall')
            this.$store.commit('closeVideoCallConfirmViewer')
        }
    }
}
</script>