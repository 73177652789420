<template>
    <v-row no-gutters class="mb-n16">
        <v-col cols="10" align="center">
            <v-card :height="appDimensions.height - 290" flat>
                <v-row no-gutters>
                    <v-col cols="3" v-for="(service, i) in $t('services')" :key="i">
                        <v-card class="ma-4" flat>
                            <v-row no-gutters class="my-2" align="center" justify="center">
                                <v-col cols="12">
                                    <v-img :src="getImage(service.serviceIcon)" contain width="150" height="243"></v-img>
                                </v-col>
                                <v-col cols="12" align-self="center" class="py-3" align="center">
                                    <span class="font-weight-bold">
                                        {{ service.serviceName }}
                                    </span>
                                </v-col>
                                <v-col cols="12" align-self="center" class="font-weight-bold" align="center">
                                    ${{ service.servicePrice }}
                                </v-col>
                                <v-col cols="12">
                                    <v-btn color="primary" @click="addToCart(service)" width="108" class="mt-2">Add</v-btn>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card>
        </v-col>
        <v-col cols="2" align="center">
            <v-card flat :height="appDimensions.height - 290" color="#F4F6F6">
                <v-card-title class="justify-center"> CART </v-card-title>
                <v-divider class="mx-2"></v-divider>
                <v-list color="#F4F6F6" :height="appDimensions.height - 500">
                    <v-list-item v-for="(item, i) in getServiceCart" :key="i" style="height: 55px;">
                        <v-col cols="2" align-self="center">
                            <v-icon color="primary">mdi-close-circle</v-icon>
                        </v-col>
                        <v-col cols="7" class="font-weight-bold" align-self="center"> {{ item.serviceName }}</v-col>
                        <v-col cols="3" class="font-weight-bold" align-self="center"> ${{ item.servicePrice }} </v-col>

                    </v-list-item>
                </v-list>
                <v-card-title> Total: <v-spacer></v-spacer> ${{ cost }} </v-card-title>
                <v-card-actions class="justify-center mb-3">
                    <v-btn width="90%" @click="placeOrder()" block color="primary"> Place Order
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-col>
    </v-row>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
    name: 'services',
    data() {
        return {
            cost: 0
        }
    },
    watch: {
        getServiceCart(newItem) {
            console.log('New Item: ', newItem)
            this.cost = this.cost + newItem[newItem.length - 1].servicePrice
        }
    },
    computed: {
        ...mapGetters(['getServiceCart', 'getCurrentLanguage', 'appDimensions'])
    },
    methods: {
        getImage(image) {
            return require('../assets/services/' + image);
        },
        addToCart(service) {
            this.$store.commit('setServiceCart', service)
        },
        placeOrder() {
            if (this.cost !== 0) {
                if (this.getCurrentLanguage === 'en-US') {
                    this.$store.dispatch('avatarSpeak', "Your order has been placed and the service provider will be delivering your order to your booth shortly.")
                } else {
                    this.$store.dispatch('avatarSpeak', "Su pedido ha sido realizado y el proveedor de servicios lo entregará en su stand en breve.")
                }
                this.$store.commit('emptyServiceCart');
                this.cost = 0
            } else {
                if (this.getCurrentLanguage === 'en-US') {
                    this.$store.dispatch('avatarSpeak', "Your cart is empty. Please select a required service to proceed.")
                } else {
                    this.$store.dispatch('avatarSpeak', "Tu carrito esta vacío. Seleccione un servicio requerido para continuar.")
                }
            }

        }
    }
}
</script>
<style>
.serviceMenu {
    height: 650px;
    border: solid #2c6b87 !important;
    width: 90%;
}

.serviceItem {
    border: thin solid #2c6b87 !important;
    margin-top: 10px;
}
</style>