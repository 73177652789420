<template>
    <v-row align="center" justify="center">
        <v-col cols="12" align="center" justify="center">
            <div class="video-panel" id="video-panel"></div>
        </v-col>
        <v-col cols="12" align="center" justify="center">
            <v-btn color="secondary" @click="endCall()" x-large>
                <v-icon class="pa-2">mdi-phone-hangup</v-icon>
                End Call
            </v-btn>
        </v-col>
    </v-row>

</template>

<script>

import { mapGetters } from 'vuex';
import router from '@/router/index.js'
export default {
    name: 'telepresence',
    computed: {
        ...mapGetters(['getKioskProfile', 'isUserLooking', 'getCallDetails'])
    },
    methods: {
        endCall() {
            this.$store.dispatch('handleEndCall').then(() => {
                this.api.executeCommand("hangup");
                router.push('/');
            })
        }
    },
    mounted() {
        this.$store.commit('setPageHeader', '')
        console.log("CREATE VIDEO BOX CALLED:", this.getKioskProfile.data().videoLink, this.getKioskProfile.data().name);
        const domain = "meet.arsconnect.com/" + this.getCallDetails.data().meetingCode;
        const options = {
            roomName: this.getCallDetails.data().meetingCode,
            width: 1500,
            height: window.innerHeight - 400,
            parentNode: document.querySelector('#video-panel'),
            interfaceConfigOverwrite: {
                filmStripOnly: false,
                TOOLBAR_BUTTONS: [],
                TOOLBAR_ALWAYS_VISIBLE: false,
                HIDE_INVITE_MORE_HEADER: true,
                DISABLE_VIDEO_BACKGROUND: true,
                DEFAULT_BACKGROUND: '#FFF',
            },
            userInfo: {
                email: '',
                displayName: this.getKioskProfile.data().name,
            },
        };
        window.JitsiMeetExternalAPI = window.JitsiMeetExternalAPI || window.exports.JitsiMeetExternalAPI;
        this.api = new window.JitsiMeetExternalAPI(domain, options);
        this.api.addListener('participantLeft', () => {
            this.noOfParticipants = this.api.getNumberOfParticipants();
            console.log("NO OF PARTICIPANTS:", this.noOfParticipants);
            if (this.noOfParticipants === 1) {
                this.endCall();
            }
        });

        this.api.addListener('participantJoined', () => {
            this.noOfParticipants = this.api.getNumberOfParticipants();
            console.log('Total number of participant', this.noOfParticipants)
            if (this.noOfParticipants >= 1) {
                //clearTimeout(callEndTimeout)
                console.log('Total number of participant speech deleted')
                this.$store.commit('clearTextBubbleText')
            }
        })
        console.log(this.api)
    },

}


</script>
