import * as fb from "@/firebase/index.js";
import crypto from "crypto";
import axios from 'axios';
import router from "@/router/index.js"
export default {
    state: {
        callListener: null,
        callEndTimer: 0,
        callDetails: null
    },
    getters: {
        getCallListener: state => state.callListener,
        getCallDetails: state => state.callDetails
    },
    mutations: {
        setCallDetails(state, obj) {
            state.callDetails = obj
        }
    },
    actions: {
        initiateCall({ getters, dispatch }) {
            if (getters.getKioskProfile.data().groups.length === 0) {
                dispatch('avatarSpeak', 'Telepresence not set up. Please contact the Law Library.')
            } else {
                dispatch('getAvailableUsers', getters.getKioskProfile.data().groups)
            }
        },

        getAvailableUsers({ dispatch, commit }, groups) {
            fb.registeredUsersCollection.where("groups", "array-contains-any", groups)
                .where("onlineStatus", "==", "Available").where("onCall", "==", false).get()
                .then(users => {
                    let availableUsers = users.docs.map(users => users.id)
                    if (availableUsers.length > 0) {
                        dispatch('startCall', availableUsers)
                    } else {
                        //commit("setVideoCallViewer", false);
                        dispatch(
                            "avatarSpeak",
                            "No users are available. Please try after sometime."
                        );
                    }
                })
        },

        startCall({ getters, commit, state, dispatch }, usersList) {
            let callObj = {
                callStatus: "Initiated",
                meetingCode: crypto.randomBytes(50).toString("hex").slice(0, 20),
                startTime: new Date(),
                endTime: null,
                conferenceAttendees: [],
                callerInfo: getters.getKioskProfile.data(),
                callInitiator: { name: getters.getKioskProfile.data().name, id: getters.getKioskProfile.id },
                callType: 'Pull',
                requestedUser: usersList,
                calledUser: usersList,
                availableUsers: usersList,
                source: true
            };

            // Add the callObj to start a video call with available Users

            fb.telepresenceCollection.add(callObj).then(response => {
                state.callEndTimer = setTimeout(() => {
                    fb.telepresenceCollection.doc(response.id).update({
                        callStatus: "notAnswered",
                        endTime: new Date(),
                        requestedUser: null
                    });
                    getters.getCallListener();
                    dispatch(
                        "avatarSpeak",
                        "Please try again after sometime."
                    );
                }, 35000);
                dispatch('declineCloudFunction', response.id)
                dispatch('handleCall', response.id)
            })
        },

        declineCloudFunction({ commit }, id) {
            const config = {
                method: 'post',
                url: 'https://us-central1-fir-application-9e5ec.cloudfunctions.net/apiV2/getCallInfo/a763cd056f1b2405788443b7197e0708',
                params: { call: id, action: 'get' }
            }
            axios(config)
                .then(result => {
                    console.log('Result from axios call', result)
                })
                .catch(error => {
                    console.log('Error : ', error.message)

                })
        },
        handleCall({ commit, state, getters, dispatch }, callId) {
            state.callListener = fb.telepresenceCollection.doc(callId).onSnapshot(
                call => {
                    console.log('Outgoing Call Tracking', call.data())
                    switch (call.data().callStatus) {
                        case 'Initiated':
                            dispatch('avatarSpeak', 'Hang On! Connecting you to a live person.')
                            break;
                        case 'inProgress':
                            commit('setCallDetails', call)
                            clearTimeout(state.callEndTimer)
                            router.push('/telepresence')
                            break;
                        case 'Declined':
                            dispatch(
                                "avatarSpeak",
                                "All requested users declined the call. Please try again after sometime."
                            );
                            clearTimeout(state.callEndTimer)
                            getters.getCallListener();
                            break;
                        case 'Ended':
                            break;
                        default:
                            console.log('Hello There !!!!')
                    }
                }
            )
        },

        handleEndCall({ getters, commit }) {
            if (getters.getCallDetails.data().requestedUser === null) {
                fb.telepresenceCollection.doc(getters.getCallDetails.id).update({
                    callStatus: 'Ended',
                    requestedUser: null
                })
            } else {
                setTimeout(function () {
                    fb.telepresenceCollection.doc(getters.getCallDetails.id).update({
                        source: false
                    })
                }, 500)
            }
            getters.getCallListener();
            commit('setTextBubbleText', 'Tap the microphone button, to ask me a question.');
        }


    }
}