import { StatusCodeError } from 'request-promise/errors'
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import store from './store'
Vue.use(VueI18n)

// The getters are to be called in the way below only when Map Datastructure is used.

// Getters for Maps -> English Language
let getMapsEnglish = () => {
  return store.getters.getMapsEnglish
}

// Getters for Maps -> Spanish Language
let getMapsSpanish = () => {
  return store.getters.getMapsSpanish
}

// Getters for Faq Category -> English Language
let getFaqCategoriesEnglish = () => {
  return store.getters.getFaqCategoriesEnglish
}

// Getters for Faq Category -> Spanish Language
let getFaqCategoriesSpanish = () => {
  return store.getters.getFaqCategoriesSpanish
}

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: {
    en: {
      homepageExhibitor: [
        { pageName: 'GET DIRECTIONS', pageIcon: 'location', route: '/maps', navigateType: 'navigate', header: 'Maps', speech: 'Here is the exhibitor map for Orlando Convention Center!' },
        { pageName: 'ORDER FOOD', pageIcon: 'food', route: '/food', navigateType: 'navigate', header: 'Order Food', speech: 'Order yummy food and drinks below.' },
        { pageName: 'VIDEO CHAT', pageIcon: 'video', route: '/telepresence', navigateType: 'dialog', header: 'Telepresence', speech: 'Please tap on continue to initiate call with the Service Desk Staff' },
        { pageName: 'BOOTH SERVICES', pageIcon: 'service', route: '/services', navigateType: 'navigate', header: 'Services', speech: 'Please select the booth services you need.' }
      ],
      homepageAttendee: [
        { pageName: 'GET DIRECTIONS', pageIcon: 'location', route: '/maps', navigateType: 'navigate', header: 'Maps', speech: 'Here is the exhibitor map for Orlando Convention Center!' },
        { pageName: 'ORDER FOOD', pageIcon: 'food', route: '/food', navigateType: 'navigate', header: 'Order Food', speech: 'Order yummy food and drinks below.' },
        { pageName: 'VIDEO CHAT', pageIcon: 'video', route: '/telepresence', navigateType: 'dialog', header: 'Telepresence', speech: 'Please tap on continue to initiate call with the Sponsorer' },
        { pageName: 'ORLANDO TOURISM', pageIcon: 'travel', route: '/tourism', navigateType: 'navigate', header: 'Orlando Tourism', speech: 'Have fun exploring Orlando, here are some great attractions and places to dine.' }
      ],
      //Please tap on countinue to initiate call with Sponsorers
      services: [
        { serviceName: 'Electric', serviceIcon: 'electric.png', servicePrice: 125 },
        { serviceName: 'Internet', serviceIcon: 'internet.png', servicePrice: 600 },
        { serviceName: 'Carpet', serviceIcon: 'carpet.png', servicePrice: 200 },
        { serviceName: 'Table & Chair', serviceIcon: 'chair.png', servicePrice: 450 }
      ],
      // Faqs
      faqs: store.getters.getFaqsEnglish,
      faqCategory: getFaqCategoriesEnglish,
      srlGuide: store.getters.getSrlGuideEnglish,

      // Maps
      maps: getMapsEnglish,
      floors: store.getters.getFloorsEnglish,

      // Departments
      departments: store.getters.getDepartmentsEnglish,
      onlineService: store.getters.getOnlineServicesEnglish
    },
    es: {
      // Homepage
      homepageExhibitor: [
        { pageName: 'Direcciones', pageIcon: 'location', route: '/maps', navigateType: 'navigate', header: 'Direcciones', speech: '¡Aquí está el mapa de expositores de Orlando centro de Convenciones!' },
        { pageName: 'Ordenar Comida', pageIcon: 'food', route: '/food', navigateType: 'navigate', header: 'Ordenar Comida', speech: 'Ordene deliciosos alimentos y bebidas a continuación.' },
        { pageName: 'Videollamada', pageIcon: 'video', route: '/telepresence', navigateType: 'dialog', header: 'Videollamada', speech: 'Toque continuar para iniciar la llamada con el personal de la mesa de servicio' },
        { pageName: 'Servicios De Stand', pageIcon: 'service', route: '/services', navigateType: 'navigate', header: 'Servicios De Stand', speech: 'Seleccione los servicios de stand que necesita.' }
      ],
      homepageAttendee: [
        { pageName: 'Direcciones', pageIcon: 'location', route: '/maps', navigateType: 'navigate', header: 'Direcciones', speech: '¡Aquí está el mapa de expositores de Expo Expo!' },
        { pageName: 'Ordenar Comida', pageIcon: 'food', route: '/food', navigateType: 'navigate', header: 'Ordenar Comida', speech: 'Ordene deliciosos alimentos y bebidas a continuación.' },
        { pageName: 'Videollamada', pageIcon: 'video', route: '/telepresence', navigateType: 'dialog', header: 'Videollamada', speech: 'Toque continuar para iniciar la llamada con el patrocinador' },
        { pageName: 'Orlando Turismo', pageIcon: 'travel', route: '/tourism', navigateType: 'navigate', header: 'Orlando Turismo', speech: 'Diviértase explorando Orlando, aquí encontrará excelentes atracciones y lugares para cenar.' }
      ],

      services: [
        { serviceName: 'Eléctrica', serviceIcon: 'electric.png', servicePrice: 125 },
        { serviceName: 'Internet', serviceIcon: 'internet.png', servicePrice: 600 },
        { serviceName: 'Alfombra', serviceIcon: 'carpet.png', servicePrice: 200 },
        { serviceName: 'Silla de Mesa', serviceIcon: 'chair.png', servicePrice: 450 }
      ],
      // Faqs
      faqs: store.getters.getFaqsSpanish,
      faqCategory: getFaqCategoriesSpanish,
      srlGuide: store.getters.getSrlGuideSpanish,
      // Maps
      maps: getMapsSpanish,
      floors: store.getters.getFloorsSpanish,

      // Departments
      departments: store.getters.getDepartmentsSpanish,
      onlineService: store.getters.getOnlineServicesSpanish
    }
  }
})
