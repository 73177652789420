import { db } from "@/firebase"
export default {
    state: {
        contentFetched: false,
        appDimensions: {},
        pageHeader: null,
        pdfViewer: {},
        imageViewer: {},
        emailViewer: {},
        optionsViewer: {},
        courtTimingViewer: {},
        videoCallConfirmViewer: {},

        //food
        menuInfoViewer: {},
        foodCart: [],

        //services
        serviceCart: [],

        // tourism
        tourismOptions: {
            themepark: false,
            attractions: false,
            eatDrink: false,
            history: false,
            shopping: false
        },

        // user session handling (Displayed on Dashboard App)
        startUserSession: undefined,
        endUserSession: undefined,
        userQnaRequest: [],
        touchRequest: [],
        userSession: {},
        sessionEndViewer: false,
        selectedRole: null,
    },
    getters: {
        getSelectedRole: state => state.selectedRole,
        contentFetched: state => state.contentFetched,
        appDimensions: state => state.appDimensions,
        getPageHeader: state => state.pageHeader,
        getPdfViewer: state => state.pdfViewer,
        getImageViewer: state => state.imageViewer,
        getEmailViewer: state => state.emailViewer,
        getOptionsViewer: state => state.optionsViewer,
        getCourtTimingViewer: state => state.courtTimingViewer,
        getStartUserSession: state => state.startUserSession,
        getEndUserSession: state => state.endUserSession,
        getTouchRequest: state => state.touchRequest,
        getUserQnaRequest: state => state.userQnaRequest,
        getSessionEndViewer: state => state.sessionEndViewer,
        getVideoCallConfirmViewer: state => state.videoCallConfirmViewer,
        getMenuInfoViewer: state => state.menuInfoViewer,
        getFoodCart: state => state.foodCart,
        getServiceCart: state => state.serviceCart,
        getTourismOption: state => state.tourismOptions
    },
    mutations: {
        setFoodCart(state, item) {
            state.foodCart.push(item)
            console.log('Food Cart', state.foodCart)
        },
        emptyFoodCart(state) {
            state.foodCart = []
        },
        setServiceCart(state, item) {
            state.serviceCart.push(item)
        },
        emptyServiceCart(state) {
            state.serviceCart = []
        },
        closeMenuInfoViewer(state) {
            state.menuInfoViewer = { show: false }
        },

        setMenuInfoViewer(state, status) {
            state.menuInfoViewer = status
        },
        setVideoCallConfirmViewer(state, confirmObj) {
            state.videoCallConfirmViewer = confirmObj
        },
        closeVideoCallConfirmViewer(state) {
            state.videoCallConfirmViewer = { show: false }
        },
        setSelectedRole(state, role) {
            state.selectedRole = role
        },
        setAppDimensions(state) {
            let newWidht = window.innerWidth;
            let newHeight = (1080 / 1920) * window.innerWidth;
            //proto: (original height / original width) x new width = new height
            // console.log(`New App Dimensions: (w X h) : (${newWidht} X ${newHeight})`);
            state.appDimensions = {
                width: newWidht,
                height: newHeight
            }
        },
        setPageHeader(state, value) {
            state.pageHeader = value;
        },
        setPdfViewer(state, pdfObj) {
            state.pdfViewer = pdfObj;
        },
        closePdfViewer(state) {
            state.pdfViewer = { show: false }
        },
        setImageViewer(state, imgObj) {
            state.imageViewer = imgObj
        },
        closeImageViewer(state) {
            state.imageViewer = { show: false }
        },
        setEmailViewer(state, emailObj) {
            state.emailViewer = emailObj
        },
        closeEmailViewer(state) {
            state.emailViewer = { show: false }
        },
        setOptionsViewer(state, optionsObj) {
            state.optionsViewer = optionsObj
        },
        closeOptionsViewer(state) {
            state.optionsViewer = { show: false }
        },
        setCourtTimingViewer(state, courtObj) {
            state.courtTimingViewer = courtObj
        },
        closeCourtTimingViewer(state) {
            state.courtTimingViewer = { show: false }
        },
        setStartUserSession(state, startUserSession) {
            state.startUserSession = startUserSession
        },
        setEndUserSession(state, endUserSession) {
            state.endUserSession = endUserSession
        },
        setTouchRequest(state, touchRequest) {
            state.touchRequest.push(touchRequest)
        },
        setUserQnaRequest(state, userQnaRequest) {
            state.userQnaRequest.push(userQnaRequest)
        },
        setSessionEndViewer(state, status) {
            state.sessionEndViewer = status
        },
        setTourismOptions(state, tourismSelect){
            console.log('Selected Tourism: ', tourismSelect)
            switch (tourismSelect.toUpperCase()) {
                case 'THEME PARKS':
                    state.tourismOptions.themepark = true
                    break;
                case 'ATTRACTIONS':
                    state.tourismOptions.attractions = true
                    break;
                case 'EAT AND DRINK':
                    console.log('Coming inside Eat and drink')
                    state.tourismOptions.eatDrink = true
                    break;
                case 'HISTORY':
                    state.tourismOptions.history = true
                    break;
                case 'SHOPPING':
                    state.tourismOptions.shopping = true
                    break;
                default:
                    state.tourismOptions.themepark = false
                    state.tourismOptions.attractions = false
                    state.tourismOptions.eatDrink = false
                    state.tourismOptions.history = false
                    state.tourismOptions.shopping = false
            }
        }   
    },
    actions: {
        openPdfViewer({ commit }, pdfObj) {
            pdfObj.show = true;
            commit('setPdfViewer', pdfObj)
        },
        openImageViewer({ commit }, imgObj) {
            imgObj.show = true
            commit('setImageViewer', imgObj)
        },
        openEmailViewer({ commit }, emailObj) {
            emailObj.show = true
            commit('setEmailViewer', emailObj)
        },
        openOptionsViewer({ commit }, optionsObj) {
            optionsObj.show = true
            commit('setOptionsViewer', optionsObj)
        },
        openCourtTimingViewer({ commit }, courtObj) {
            courtObj.show = true
            commit('setCourtTimingViewer', courtObj)
        },
        updateUserSession({ getters, state }) {
            db.collection('userSession').add({
                kioskID: getters.getKioskId,
                userSessionStartTime: getters.getStartUserSession,
                userSessionEndTime: getters.getEndUserSession,
                userSessionLogs: JSON.stringify(getters.getTouchRequest),
                //userQnaRequest: JSON.stringify(getters.getUserQnaRequest)
            }).then(response => {
                console.log('User Handling Data saved to firebase' + new Date())
                state.touchRequest = []
                state.userQnaRequest = []
            })
        },
    }
}
