<template>
    <v-dialog width="500" v-model="getMenuInfoViewer.show" @click:outside="clearSelection()">
        <v-card width="100%">
            <v-row no-gutters class="fill-height">
                <v-col cols="3" align-self="center">
                    <span class="pl-2 text-subtitle-1 font-weight-bold">{{ getMenuInfoViewer.name }}</span>
                </v-col>
                <v-col cols="9">
                    <v-text-field label="Enter Quantity" v-model="number" class="px-3"></v-text-field>
                </v-col>
            </v-row>
            <v-row no-gutters align="center" justify="center">
                <v-col cols="2" v-for="(n, i) in 10" :key="i" class="ma-1 pl-1" align-self="center" align="center"
                    justify="center">
                    <v-btn class="ma-1" width="80%" @click="number = n" color="primary" outlined>
                        {{ n }}
                    </v-btn>
                </v-col>
                <v-col cols="12" align-self="center" align="center" class="mt-4 mb-4">
                    <v-btn width="175" @click="addToCart()" color="primary">Add to Cart</v-btn>
                </v-col>
            </v-row>
        </v-card>
    </v-dialog>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    name: 'menuSelection',
    computed: {
        ...mapGetters(["getMenuInfoViewer"]),
    },
    data() {
        return {
            options: ['Yes', 'No'],
            custom: {},
            number: null
        }
    },
    methods: {
        addToCart() {
            //setFoodCart
            console.log('dfhsgfhjhgkdfg: ', this.getMenuInfoViewer)
            if (this.number !== null) {
                if (this.getMenuInfoViewer.type === 'food') {
                    let data = {
                        itemName: this.getMenuInfoViewer.name,
                        price: Math.round((this.number * this.getMenuInfoViewer.price) * 100) / 100,
                        changes: this.custom,
                        quantity: this.number,
                        type: 'food'
                    }
                    this.$store.commit('setFoodCart', data)
                } else {
                    let data = {
                        itemName: this.getMenuInfoViewer.name,
                        price: Math.round((this.number * this.getMenuInfoViewer.price) * 100) / 100,
                        quantity: this.number,
                        type: 'drink'
                    }
                    this.$store.commit('setFoodCart', data)
                }
                this.$store.commit('closeMenuInfoViewer')
                this.custom = {}
                this.number = null
            } else {
                this.$store.dispatch('avatarSpeak', "Quantity cannot be empty")
            }

        },
        clearSelection() {
            this.$store.commit('closeMenuInfoViewer')
            this.custom = {}
            this.number = null
        },
        getImage(image) {
            if (image !== undefined) {
                return require('@/assets/food/' + image);
            }

        }
    }
}
</script>