import Vue from 'vue';
import VueRouter from 'vue-router';
import { auth } from "@/firebase";
import Home from '../views/Home.vue';
import login from '@/views/login.vue';
import faqs from '@/views/faqs.vue';
import maps from '@/views/maps.vue';
import forms from '@/views/forms.vue';
import food from '@/views/food.vue';
import services from '@/views/services.vue';
import loadData from '@/views/loadData.vue';
import recognition from '@/components/recognition.vue';
import departments from "@/views/departments";
import telepresence from '@/views/telepresence.vue';
import store from '@/store';
import digitalForms from '@/views/digitalForms.vue';
import tourism from '@/views/tourism.vue';
Vue.use(VueRouter)

const routes = [
  {
    path: '/loadData',
    name: 'loadData',
    component: loadData,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/rec',
    name: 'recognition',
    component: recognition,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/login',
    name: 'login',
    component: login
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true,
      requireDataLoaded: true,
    },
  },
  {
    path: '/faqs',
    name: 'faqs',
    component: faqs,
    meta: {
      requiresAuth: true,
      requireDataLoaded: true,
    },
  },
  {
    path: '/maps',
    name: 'maps',
    component: maps,
    meta: {
      requiresAuth: true,
      requireDataLoaded: true,
    },
  },
  {
    path: '/food',
    name: 'food',
    component: food,
    meta: {
      requiresAuth: true,
      requireDataLoaded: true,
    },
  },
  {
    path: '/services',
    name: 'services',
    component: services,
    meta: {
      requiresAuth: true,
      requireDataLoaded: true,
    },
  },
  {
    path: '/tourism',
    name: 'toursim',
    component: tourism,
    meta: {
      requiresAuth: true,
      requireDataLoaded: true,
    },
  },
  {
    path: '/forms',
    name: 'forms',
    component: forms,
    meta: {
      requiresAuth: true,
      requireDataLoaded: true,
    },
  },
  {
    path: '/digitalForms',
    name: 'digitalForms',
    component: digitalForms,
    meta: {
      requiresAuth: true,
      requireDataLoaded: true,
    },
  },
  {
    path: '/departments',
    name: 'departments',
    component: departments,
    meta: {
      requiresAuth: true,
      requireDataLoaded: true,
    },
  },
  {
    path: '/telepresence',
    name: 'telepresence',
    component: telepresence,
    meta: {
      requiresAuth: true,
      requireDataLoaded: true,
    },
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  //console.log('How many times you are coming to the router')
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth);
  const requireDataLoaded = to.matched.some(x => x.meta.requireDataLoaded);

  if (requiresAuth && !auth.currentUser) {
    next('/login');
  }
  else if (requireDataLoaded && !store.getters.getDataLoaded) {
    next('/loadData');
  }
  else {
    next();
  }
})

export default router
